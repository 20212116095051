.main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
}

.nav {
  width: 336px;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}

.page {
  margin-top: 64px;
  height: calc(var(--vh, 1vh) * 100 - 64px);
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
}
.page>*:first-child {
  height: 100%;
}

.page:has(.publicReport) {
  margin-top:0px;
  height:100%;
}


@media (max-width: 600px) {
  .page {
    margin-top: 56px;
    height: calc(var(--vh, 1vh) * 100 - 56px);
  }
}